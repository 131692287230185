import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const BookScaleWrapper = styled.div`
  border-left: 1px solid ${props => props.theme.colors.button};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  width: 42px;
  margin-left: 1em;
  transition: opacity 200ms ease-out;
`

const BookScaleIndicator = styled.span`
  position: relative;
  font-size: 12px;
  height: 1px;
  width: 10px;
  background: ${props => props.theme.colors.button};

  span {
    width: 30px;
    display: block;
    position: absolute;
    left: 15px;
    top: 0;
    transform: translateY(-50%);
  }
`

const BookScale = props => {
  const { ratio, scale, disabled } = props

  let divisor = 1

  if (ratio * scale > 110) divisor = 2
  if (ratio * scale > 140) divisor = 4
  if (ratio * scale > 400) divisor = 8

  return (
    <BookScaleWrapper
      style={{
        height: `${(ratio * 4 * scale) / divisor}px`,
        opacity: disabled ? 0.5 : 0.7,
      }}
    >
      <BookScaleIndicator>
        <span>{4 / divisor}</span>
      </BookScaleIndicator>
      <BookScaleIndicator>
        <span>{3 / divisor}</span>
      </BookScaleIndicator>
      <BookScaleIndicator>
        <span>{2 / divisor}</span>
      </BookScaleIndicator>
      <BookScaleIndicator>
        <span>{1 / divisor}</span>
      </BookScaleIndicator>
      <BookScaleIndicator>
        <span>0 cm</span>
      </BookScaleIndicator>
    </BookScaleWrapper>
  )
}

BookScale.propTypes = {}

export default BookScale
