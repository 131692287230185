import React, { useCallback, useState } from "react"
import { TransformWrapper } from "@lib/react-zoom-pan-pinch"

const WithZoom = (Component, timeout) => props => {
  const { defaultScale } = props

  return (
    <TransformWrapper
      defaultValues={{
        defaultScale,
      }}
      defaultScale={defaultScale}
      options={{
        minScale: defaultScale,
        maxScale: 1.5 + defaultScale,
      }}
      pan={{
        velocity: false,
        paddingSize: 0,
      }}
      wheel={{
        disabled: true,
      }}
      doubleClick={{
        disabled: true,
      }}
      zoomIn={{
        animationType: "easeInOutCubic",
        animationTime: timeout,
        step: 0.5,
      }}
      zoomOut={{
        animationType: "easeInOutCubic",
        animationTime: timeout,
        step: 0.5,
      }}
    >
      {zoomProps => <Component {...props} {...zoomProps} />}
    </TransformWrapper>
  )
}

WithZoom.propTypes = {}

export default WithZoom
