import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import styled from "@emotion/styled"
import ReactTooltip from "react-tooltip"
import { TransformComponent } from "@lib/react-zoom-pan-pinch"
import BookFolio from "./BookFolio"
import BookScale from "./BookScale"
import { BookContext } from "./BookManager"
import BookButton, { BookButtonIcon } from "./BookButton"
import BookPage from "./BookPage"
import WithZoom from "./withZoom"
import BookNextIcon from "@src/assets/icons/arrow-right.inline.svg"
import BookPrevIcon from "@src/assets/icons/arrow-left.inline.svg"
import BookZoomIcon from "@src/assets/icons/zoom.svg"
import BookUnzoomIcon from "@src/assets/icons/unzoom.svg"
import BookFolioIcon from "@src/assets/icons/folio-open.svg"
import BookCloseFoliocon from "@src/assets/icons/folio-close.svg"
import BookHandIcon from "@src/assets/icons/hand.svg"
import BookAnimationIcon from "@src/assets/images/livre.inline.svg"
import { Transition, TransitionGroup } from "react-transition-group"
import { withTheme } from "emotion-theming"
import "@src/assets/images/livre_anime.css"
import { graphql, useStaticQuery } from "gatsby"

const zoomTimeout = 350
const transitionDuration = 1500
const transitionStyles = {
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
    transition: "all 250ms ease-in-out 300ms",
  },
  exiting: {
    transition: "all 250ms ease-in-out",
    opacity: 0,
  },
  exited: {
    pointerEvents: "none",
    opacity: 0,
  },
}

// New
const actionsWidth = 64
const arrowWidth = 80

const BookContainer = styled.div`
  position: relative;
  border-top: ${props => props.theme.border};
  overflow: hidden;
  height: 100%;

  * {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
`

const BookFolioWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(${props => (props.active ? "0" : "100%")});

  padding-right: 80px;
  width: 100%;
  // min-height: 165px;
  // height: 20vh;

  z-index: ${props => props.theme.zIndices.folio};
  background-color: ${props => props.theme.colors.background};
  transition: transform 0.2s ease-out;

  &:before {
    display: block;
    content: "";
    width: 100%;
    height: 0;
    position: absolute;
    top: -1px;
    border-top: ${props => props.theme.border};
  }

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    position: fixed;
    bottom: -1px;
    padding-left: 20px;
  }
`

const BookViewer = styled.div`
  position: relative;
  padding-right: ${actionsWidth + arrowWidth}px;
  padding-left: ${actionsWidth + arrowWidth}px;
  height: 100%;
`

const BookInformations = styled.div`
  width: 100%;
  height: 100%;
  z-index: ${props => props.theme.zIndices.infos};
`

const BookInformationsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const BookActions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding-top: 80px;
  padding-bottom: 80px;
  height: 100%;
  width: ${actionsWidth}px;

  z-index: ${props => props.theme.zIndices.actions};
`

const BookArrowButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  max-width: 100%;
  max-height: 100%;

  z-index: ${props => props.theme.zIndices.arrows};
  pointer-events: none;
`

const BookArrowButton = styled(BookButton)`
  position: absolute;
  top: 0;
  left: -${arrowWidth}px;
  margin: 0;
  padding-right: 20px;
  padding-left: 20px;
  width: ${arrowWidth}px;
  height: 100%;
  pointer-events: auto;

  &.isRight {
    left: auto;
    right: -${arrowWidth}px;
  }

  svg {
    width: 40px;
    height: 40px;
  }
`

const BookActionButton = styled(BookButton)``

const BookFolioAction = styled(BookActionButton)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-60%);
`

const BookPageIndicatorList = styled.div`
  position: absolute;
  width: 100%;
  bottom: 97%;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const BookPageIndicator = styled.div`
  position: relative;
  font-size: 12px;
  margin-bottom: 2em;
  padding-bottom: 6px;
  color: #6c7c74;
  text-transform: uppercase;
  &:before {
    content: "";
    display: block;
    position: absolute;
    height: 2em;
    border-left: 1px dashed #6c7c74;
    top: 100%;
    left: 50%;
  }
`

const BookInfo = styled.div`
  cursor: pointer;
  position: absolute;
  // background: rgba(0, 0, 0, .3);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 200ms;
  will-change: opacity;
  min-width: 60px;
  min-height: 60px;

  img {
    max-width: 60px;
    max-height: 60px;
  }

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    min-width: 46px;
    min-height: 46px;

    img {
      max-width: 46px;
      max-height: 46px;
    }
  }

  .no-touchevents & {
    opacity: 0.65;

    &:hover {
      opacity: 1;
    }
  }

  &.isSelect {
    opacity: 0.15 !important;
  }
`

const BookTooltip = styled(ReactTooltip)`
  background-color: rgba(236, 227, 221, 0.85) !important;
  max-width: 320px;
  padding: 12px !important;
  color: #5e5a58;

  h3 {
    color: #606660;
    margin: 0;
    font-weight: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: ${props => props.theme.fontSizes.large.description};
    text-align: justify;
    text-transform: uppercase;

    @media (max-width: ${props => props.theme.breakpoints.large}) {
      font-size: ${props => props.theme.fontSizes.medium.description};
    }
  }

  &.isInfo p {
    font-style: italic;
    padding-top: 3px;
  }

  p {
    color: #606660;
    font-size: ${props => props.theme.fontSizes.large.description};
    margin: 0;
    text-align: justify;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media (max-width: ${props => props.theme.breakpoints.large}) {
      font-size: ${props => props.theme.fontSizes.medium.description};
    }
  }

  &:before,
  &:after {
    transform: scale(2);
  }

  &.place-top:after {
    border-top-color: rgba(236, 227, 221, 0.8) !important;
  }
  &.place-left:after {
    border-left-color: rgba(236, 227, 221, 0.8) !important;
  }
  &.place-bottom:after {
    border-bottom-color: rgba(236, 227, 221, 0.8) !important;
  }
  &.place-right:after {
    border-right-color: rgba(236, 227, 221, 0.8) !important;
  }
`

const BookRotateButton = styled(BookInfo)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 64px;
  height: 64px;

  padding: 0;
  margin: 0;
`

const BookCursor = styled.img`
  position: fixed;
  display: none;
  z-index: 9999;
  cursor: none;
  pointer-events: none;
  width: 54px;
  height: 54px;
  transform: translate(-50%, -50%);
`

const Book = props => {
  const {
    pages,
    index,
    cover,
    isCover,
    isJump,
    currentPage,
    setPage,
    nextPage,
    prevPage,
  } = useContext(BookContext)

  const { zoomIn, zoomOut, resetTransform, scale, ratio } = props
  const data = useStaticQuery(graphql`
    query BookIcons {
      info: file(relativePath: { eq: "icone_info.png" }) {
        childImageSharp {
          resize(width: 62, height: 62, pngQuality: 100) {
            src
          }
        }
      }
      infox2: file(relativePath: { eq: "icone_info.png" }) {
        childImageSharp {
          resize(width: 124, height: 124, pngQuality: 100) {
            src
          }
        }
      }
      translate: file(relativePath: { eq: "icone_traduction.png" }) {
        childImageSharp {
          resize(width: 62, height: 62, pngQuality: 100) {
            src
          }
        }
      }
      translatex2: file(relativePath: { eq: "icone_traduction.png" }) {
        childImageSharp {
          resize(width: 124, height: 124, pngQuality: 100) {
            src
          }
        }
      }
      rotate: file(relativePath: { eq: "icone_180.png" }) {
        childImageSharp {
          resize(width: 62, height: 62, pngQuality: 100) {
            src
          }
        }
      }
      rotatex2: file(relativePath: { eq: "icone_180.png" }) {
        childImageSharp {
          resize(width: 124, height: 124, pngQuality: 100) {
            src
          }
        }
      }
    }
  `)

  console.log(data)

  // States
  const cursorRef = useRef()
  const debounceFolio = useRef()
  const icons = useRef([])
  const wheelDebounce = useRef(false)
  const [isScroll, setScroll] = useState(false)
  const [reverse, setReverse] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const [isRotate, setIsRotate] = useState(false)
  const [isTouch, setIsTouch] = useState(Modernizr.touchevents)
  const isZoom = useMemo(() => scale > props.defaultScale + 0.1, [scale])
  const [isTransition, setIsTransition] = useState(false)
  const disabledAction = useMemo(() => isTransition, [isTransition])
  const needRotate = useMemo(() => currentPage.rotate && !isRotate, [
    currentPage,
    isRotate,
  ])

  const size = useMemo(
    () => [2769, 2769 / currentPage.src.childImageSharp.page.aspectRatio],
    [currentPage]
  )

  // Actions
  const toggleFolioAction = useCallback(() => setScroll(!isScroll), [
    isScroll,
    setScroll,
  ])

  const toggleIconAction = useCallback(
    icon => {
      if (icon.classList.contains("isSelect")) icon.classList.remove("isSelect")
      else icon.classList.add("isSelect")
      setScroll(false)
    },
    [setScroll]
  )

  const nextAction = useCallback(() => {
    resetTransform()
    setReverse(false)
    setScroll(false)
    setTimeout(() => nextPage(), zoomTimeout)
  }, [nextPage, setReverse, resetTransform, setScroll])

  const prevAction = useCallback(() => {
    resetTransform()
    setReverse(true)
    setScroll(false)
    setTimeout(() => prevPage(), zoomTimeout)
  }, [prevPage, setReverse, resetTransform, setScroll])

  const selectAction = useCallback(
    newIndex => {
      if (disabledAction) return
      resetTransform()
      setReverse(newIndex < index)
      setTimeout(() => setPage(newIndex), zoomTimeout)
    },
    [nextPage, setReverse, resetTransform, index, disabledAction]
  )

  const zoomInAction = useCallback(
    e => {
      setScroll(false)
      zoomIn(e)
    },
    [zoomIn, disabledAction, setScroll]
  )

  const zoomOutAction = useCallback(
    e => {
      setScroll(false)
      zoomOut(e)
    },
    [zoomOut, disabledAction, setScroll]
  )

  // Custom Mouse
  const onMouseEnter = useCallback(() => {
    if (isZoom) cursorRef.current.style.display = "block"
  }, [isZoom])
  const onMouseLeave = useCallback(() => {
    cursorRef.current.style.display = "none"
  }, [])
  const onMouseMove = useCallback(e => {
    cursorRef.current.style.top = `${e.pageY}px`
    cursorRef.current.style.left = `${e.pageX}px`
  }, [])
  const onMouseWheel = useCallback(
    e => {
      const deltaY = e.deltaY
      if (!wheelDebounce.current && deltaY < 0) zoomInAction(e)
      else if (!wheelDebounce.current && deltaY > 0) zoomOutAction(e)
      if (deltaY != 0) {
        wheelDebounce.current = true
        setTimeout(() => {
          wheelDebounce.current = false
        }, zoomTimeout)
      }
    },
    [zoomInAction, zoomOutAction]
  )

  // Close folio
  const closeFolio = useCallback(() => {
    clearTimeout(debounceFolio.current)
    debounceFolio.current = setTimeout(() => setScroll(false), 1600)
  }, [setScroll])

  const stopCloseFolio = useCallback(() => {
    clearTimeout(debounceFolio.current)
  }, [])

  useEffect(() => {
    setIsTouch(Modernizr.touchevents)

    const resize = () => resetTransform()

    window.addEventListener("resize", resize)

    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [setIsTouch, Modernizr.touchevents, resetTransform])

  // Cache components
  const Actions = useMemo(
    () => (
      <BookActions>
        <section>
          <BookActionButton disabled={disabledAction} onClick={zoomInAction}>
            <BookButtonIcon src={BookZoomIcon} />
          </BookActionButton>
          <BookActionButton disabled={disabledAction} onClick={zoomOutAction}>
            <BookButtonIcon src={BookUnzoomIcon} />
          </BookActionButton>
        </section>
        <BookScale disabled={disabledAction} scale={scale} ratio={ratio} />
        <section>
          <BookActionButton
            disabled={disabledAction}
            onClick={toggleFolioAction}
          >
            <BookButtonIcon src={BookFolioIcon} />
          </BookActionButton>
        </section>
      </BookActions>
    ),
    [
      toggleFolioAction,
      zoomInAction,
      zoomOutAction,
      ratio,
      scale,
      disabledAction,
    ]
  )

  const Folio = useMemo(
    () => (
      <BookFolioWrapper
        active={isScroll}
        onMouseLeave={closeFolio}
        onMouseEnter={stopCloseFolio}
      >
        <BookFolio
          pages={pages}
          cover={cover}
          onSelectPage={selectAction}
          index={index}
        />
        <BookFolioAction onClick={toggleFolioAction}>
          <BookButtonIcon src={BookCloseFoliocon} />
        </BookFolioAction>
      </BookFolioWrapper>
    ),
    [
      isScroll,
      index,
      toggleFolioAction,
      selectAction,
      closeFolio,
      stopCloseFolio,
    ]
  )

  return (
    <BookContainer
      as={props.as}
      className={props.className}
      style={props.style}
    >
      <Transition in={isTransition && isJump} timeout={transitionDuration}>
        {state => (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              opacity: state === "entering" ? 1 : 0,
              transition: "all 200ms",
              zIndex: 1000,
              pointerEvents: state === "exited" ? "none" : "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              background: "rgba(25, 33, 30, .4)",
            }}
          >
            <BookAnimationIcon style={{ width: "136px", height: "136px" }} />
            <p
              style={{
                fontSize: "20px",
                fontWeight: "light",
                color: "#C7C9C7",
                textTransform: "uppercase",
                margin: "10px 0",
              }}
            >
              Folio{" "}
              {reverse
                ? `${currentPage.folio.right} R`
                : `${currentPage.folio.left} V`}
            </p>
            <span
              style={{ width: "50px", height: "2px", background: "#C7C9C7" }}
            ></span>
          </div>
        )}
      </Transition>
      <BookViewer>
        {Actions}
        <BookCursor ref={cursorRef} src={BookHandIcon} />
        <BookInformationsWrapper>
          <TransformComponent>
            <div
              style={{ cursor: isZoom ? "none" : "auto" }}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onMouseMove={onMouseMove}
              onWheel={onMouseWheel}
            >
              <TransitionGroup>
                <Transition
                  key={index}
                  timeout={transitionDuration}
                  onExiting={() => setIsTransition(true)}
                  onEntered={() => setIsRotate(false)}
                  onExited={() => setIsTransition(false)}
                >
                  {state => (
                    <BookPage
                      rotation={
                        ["entered", "exiting", "exited"].includes(state)
                          ? isRotate
                          : false
                      }
                      jump={isJump || isCover}
                      page={currentPage}
                      state={state}
                      reverse={reverse}
                      size={size}
                    />
                  )}
                </Transition>
              </TransitionGroup>
            </div>
          </TransformComponent>
          {!isZoom && (
            <TransitionGroup>
              <Transition key={index} timeout={transitionDuration}>
                {state => (
                  <div
                    style={{
                      ...transitionStyles[state],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      display:
                        index !== 0 && index !== pages.length - 1
                          ? "block"
                          : "none",
                    }}
                  >
                    <BookInformations
                      style={{
                        width: `${size[0] * scale}px`,
                        height: `${size[1] * scale}px`,
                      }}
                      onMouseEnter={() => setIsHover(true)}
                      onMouseMove={() => setIsHover(true)}
                      onMouseLeave={() => setIsHover(false)}
                    >
                      <BookPageIndicatorList
                        style={{
                          flexDirection: isRotate ? "row-reverse" : "row",
                        }}
                      >
                        <BookPageIndicator>
                          Folio {currentPage.folio.left} VERSO
                        </BookPageIndicator>
                        <BookPageIndicator>
                          Folio {currentPage.folio.right} RECTO
                        </BookPageIndicator>
                      </BookPageIndicatorList>
                      <div
                        style={{
                          opacity:
                            isTransition || (!isTouch && !isHover) ? 0 : 1,
                          transition: "all 500ms ease-in-out",
                        }}
                      >
                        {!needRotate && (
                          <>
                            {currentPage.items.map((i, pIndex) => (
                              <BookInfo
                                ref={ref => (icons.current[pIndex] = ref)}
                                key={pIndex}
                                data-tip
                                data-for={`tooltip-${pIndex}`}
                                data-event="click"
                                style={{
                                  top: `${i.y}%`,
                                  left: `${i.x}%`,
                                  width: `${i.w}%`,
                                  height: `${i.h}%`,
                                }}
                              >
                                {i.title ? (
                                  <img
                                    src={data.info.childImageSharp.resize.src}
                                    srcSet={`${data.info.childImageSharp.resize.src} x1, ${data.infox2.childImageSharp.resize.src} x2`}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={
                                      data.translate.childImageSharp.resize.src
                                    }
                                    srcSet={`${data.translate.childImageSharp.resize.src} x1, ${data.translatex2.childImageSharp.resize.src} x2`}
                                    alt=""
                                  />
                                )}
                              </BookInfo>
                            ))}
                            {currentPage.items.map((i, pIndex) => (
                              <BookTooltip
                                id={`tooltip-${pIndex}`}
                                key={pIndex}
                                afterShow={() =>
                                  i.title &&
                                  toggleIconAction(icons.current[pIndex])
                                }
                                afterHide={() =>
                                  i.title &&
                                  toggleIconAction(icons.current[pIndex])
                                }
                                isCapture={true}
                                effect="solid"
                                place={i.position}
                                className={i.title ? "isInfo" : "isTranslate"}
                                globalEventOff="click"
                              >
                                {i.title && (
                                  <h3
                                    dangerouslySetInnerHTML={{
                                      __html: i.title,
                                    }}
                                  />
                                )}
                                {i.content && (
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: i.content,
                                    }}
                                  />
                                )}
                              </BookTooltip>
                            ))}
                          </>
                        )}
                        {currentPage.rotate && (
                          <BookRotateButton
                            onClick={() => setIsRotate(!isRotate)}
                          >
                            <img
                              src={data.rotate.childImageSharp.resize.src}
                              srcSet={`${data.rotate.childImageSharp.resize.src} x1, ${data.rotatex2.childImageSharp.resize.src} x2`}
                              alt=""
                            />
                          </BookRotateButton>
                        )}
                      </div>
                    </BookInformations>
                  </div>
                )}
              </Transition>
            </TransitionGroup>
          )}
          <Transition in={!isTransition} timeout={0}>
            {state => (
              <BookArrowButtonWrapper
                style={{
                  width: `${size[0] * scale}px`,
                  height: `${size[1] * scale}px`,
                  ...transitionStyles[state],
                }}
              >
                <BookArrowButton
                  style={{
                    opacity:
                      ["entering", "entered"].includes(state) && index !== 0
                        ? 1
                        : 0,
                  }}
                  disabled={disabledAction}
                  onClick={prevAction}
                >
                  <BookPrevIcon />
                </BookArrowButton>
                <BookArrowButton
                  style={{
                    opacity:
                      ["entering", "entered"].includes(state) &&
                      index !== pages.length - 1
                        ? 1
                        : 0,
                  }}
                  disabled={disabledAction}
                  onClick={nextAction}
                  className={"isRight"}
                >
                  <BookNextIcon />
                </BookArrowButton>
              </BookArrowButtonWrapper>
            )}
          </Transition>
        </BookInformationsWrapper>
      </BookViewer>
      {Folio}
    </BookContainer>
  )
}

export default withTheme(WithZoom(Book, zoomTimeout))
