import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const linkStyle = props => css`
  color: ${props.color || props.theme.colors.button};
  text-decoration: none;

  font-size: ${props.theme.fontSizes.large.link};

  transition: opacity 200ms ease-out;
  opacity: 0.7;
  outline: none;

  &:focus {
    outline: none;
  }

  .touchevents {
    opacity: 1;
  }

  .no-touchevents &:hover {
    opacity: 1;
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
  }

  span {
    display: inline-block;
    line-height: 1.1em;
    border-bottom: 1px solid ${props.color || props.theme.colors.button};
  }

  * {
    stroke: ${props.color || props.theme.colors.button} !important;
  }

  @media (max-width: ${props.theme.breakpoints.large}) {
    font-size: ${props.theme.fontSizes.medium.link};
  }
`

export default styled(Link)`
  ${linkStyle};
`

export { linkStyle }
