import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import Siema from "siema"
import BookNextIcon from "@src/assets/icons/arrow-right.svg"
import BookPrevIcon from "@src/assets/icons/arrow-left.svg"
import BookJumpIcon from "@src/assets/icons/jump.svg"

const ScrollWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-right: 45px;
  padding-left: 45px;
  overflow: hidden;
`

const Slider = styled.div`
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  > * {
    height: 100%;
  }
`

const Slide = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
`

const SlideImage = styled.img`
  display: block;
  width: 100%;
  box-sizing: border-box;
`

const SliderButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 0.65;
  transition: opacity 200ms ease-in-out;
  &:hover {
    opacity: 1;
  }
`

const SliderIcon = styled.img`
  display: inline-block;
  height: 100%;
  width: 30px;
`

const SlideJump = styled.div`
  margin: 0 12px;
  border-left: 2px solid #6c7c74;
  border-right: 2px solid #6c7c74;
  text-align: center;

  img {
    width: 3.7vw;
    margin-top: 2vw;
    margin-bottom: 2vw;

    @media (max-width: ${props => props.theme.breakpoints.large}) {
      width: 5.2vw;
    }
  }
`

const BookFolio = props => {
  const { pages, index, onSelectPage, cover, ...rest } = props
  const slider = useRef()
  const [siema, setSiema] = useState(null)

  useEffect(() => {
    if (!siema) {
      setSiema(
        new Siema({
          selector: slider.current,
          perPage: window.innerWidth > 1440 ? 8 : 6,
          loop: false,
          draggable: false,
        })
      )
    }
  }, [siema, setSiema])

  const pagesWithJump = useMemo(() => {
    const result = []

    pages.forEach(p => {
      result.push(p)
      if (p.jump) result.push(false)
    })

    return result
  }, [pages])

  return (
    <ScrollWrapper {...rest}>
      <SliderButton
        style={{ left: 0, right: "auto" }}
        onClick={() => siema.prev(2)}
      >
        <SliderIcon src={BookPrevIcon} />
      </SliderButton>
      <Slider ref={slider}>
        {pagesWithJump.map((page, i) => {
          const pageIndex = pages.indexOf(page)

          return page ? (
            <Slide onClick={() => onSelectPage(pageIndex)} key={i}>
              <div
                style={{
                  border:
                    pageIndex === index
                      ? "1px solid #6C7C74"
                      : "1px solid transparent",
                  marginBottom: "4px",
                  padding: "4px 5px 3px 5px",
                }}
              >
                <SlideImage
                  src={page.src.childImageSharp.thumb.src}
                  alt={page.ref}
                  loading={"eager"}
                  fadeIn={false}
                />
              </div>
              {i === 0 || i === pagesWithJump.length - 1 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  <span>Couverture</span>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  <span>F° {page.folio.left} V</span>
                  <span>F° {page.folio.right} R</span>
                </div>
              )}
            </Slide>
          ) : (
            <SlideJump key={i}>
              <img src={BookJumpIcon} alt="" />
            </SlideJump>
          )
        })}
      </Slider>
      <SliderButton onClick={() => siema.next(2)}>
        <SliderIcon src={BookNextIcon} />
      </SliderButton>
    </ScrollWrapper>
  )
}

BookFolio.propTypes = {
  pages: PropTypes.array,
}

BookFolio.defaultProps = {
  pages: [],
}

export default BookFolio
