import React, { useCallback, useRef, useState } from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image"

const transitionContainerStyles = {
  exiting: {
    position: "absolute",
    top: 0,
    left: 0,
  },
}

const transitionPanelJumpStyles = {
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
    transition: "opacity 500ms ease-in",
  },
  exiting: {
    opacity: 0,
    transition: "opacity 500ms ease-in 900ms",
  },
}

const transitionPanelStyles = {
  exiting: {
    width: 0,
    transition: "width 1200ms ease-in 300ms",
  },
}

const transitionLine = reverse => ({
  exiting: {
    opacity: 1,
    transform: `translateX(${reverse ? "100%" : "-100%"})`,
    transition: "opacity 150ms, transform 1200ms ease-in 300ms",
  },
})

const BookPageContainer = styled.div`
  display: flex;
`

const BookPagePanel = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${props => props.theme.colors.background};
`

const BookPagePaneLine = styled.div`
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 100;

  &.reverse:after {
    right: auto;
    left: -12px;
    transform: rotate(0deg);
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: -12px;
    left: auto;
    transform: rotate(180deg);
    height: 100%;
    width: 12px;
    border-right: 2px solid #a18b70;
    background: linear-gradient(
      270deg,
      rgba(45, 45, 45, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`

const BookPagePaneImg = styled(Img)`
  position: absolute !important;
  top: 0;
  display: block;
  image-rendering: high-quality;
`

const BookPage = props => {
  const { page, state, size, reverse, jump, ...rest } = props
  const wrapperRef = useRef()
  const [preload, setPreload] = useState(false)

  const currentTransition = jump
    ? transitionPanelJumpStyles
    : transitionPanelStyles

  return (
    <BookPageContainer
      ref={wrapperRef}
      style={{
        width: size[0],
        height: size[1],
        justifyContent: reverse ? "flex-end" : "flex-start",
        ...transitionContainerStyles[state],
      }}
      {...rest}
    >
      <BookPagePaneLine
        className={reverse ? "reverse" : ""}
        style={{ ...(jump ? {} : transitionLine(reverse)[state]) }}
      />
      <BookPagePanel
        className={state === "entering" && jump ? "isJump" : ""}
        style={{ ...currentTransition[state] }}
      >
        <img
          src={page.src.childImageSharp.preview.src}
          alt=""
          style={{
            position: "absolute",
            width: size[0],
            height: size[1],
            left: reverse ? "auto" : 0,
            right: reverse ? 0 : "auto",
            transform: `rotateZ(${props.rotation ? 180 : 0}deg)`,
            opacity: preload ? 0 : 1,
            transition: "opacity 150ms ease-in",
          }}
        />
        <BookPagePaneImg
          fixed={page.src.childImageSharp.page}
          fadeIn={false}
          onLoad={() => setPreload(true)}
          alt=""
          style={{
            width: size[0],
            height: size[1],
            left: reverse ? "auto" : 0,
            right: reverse ? 0 : "auto",
            transform: `rotateZ(${props.rotation ? 180 : 0}deg)`,
            opacity: preload ? 1 : 0,
          }}
        />
      </BookPagePanel>
    </BookPageContainer>
  )
  /*return (
    <div style={{ display: "flex", justifyContent: reverse ? "flex-end" : "flex-start", ...transitionContainerStyles[state] }}>
      <BookPagePaneLine style={{ right: reverse ? "auto" : "-10%", left: reverse ? "-10%" : "auto", ...transitionLine[state] }}/>
      <BookPagePane style={{ height: `82vh`, ...transitionChildStyles[state] }}>
        <BookPagePaneImg fluid={page.src.childImageSharp.fluid} alt="" style={{ width: `${page.src.childImageSharp.fluid.aspectRatio * 82}vh`,left: reverse ? "auto" : 0, right: reverse ? 0 : "auto" }}/>
        {/!*<div style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: `100%`,
          height: `100%`,
          // background: "rgba(255, 255, 255, 0.2)",
        }}>
          {page.items.map((i, index) => (
            <Widget key={index} widget={i} src={page.src}/>
          ))}
        </div>*!/}
      </BookPagePane>
    </div>
  )*/
}

BookPage.propTypes = {}

export default BookPage
