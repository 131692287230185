import styled from "@emotion/styled"
import { linkStyle } from "@src/components/Link"

const BookButton = styled.button`
  display: block;

  margin-bottom: 12px;
  border: none;
  height: 34px;

  outline: none;
  background-color: transparent;
  cursor: pointer;

  ${linkStyle};

  svg,
  img {
    display: block;
    height: 100%;
  }
`

const BookButtonIcon = styled.img``

export default BookButton
export { BookButtonIcon }
