import React, { useContext, useEffect, useMemo, useState } from "react"
import Book from "@src/components/book"
import { graphql } from "gatsby"
import Container from "@src/components/Container"
import Header from "@src/components/Header"
import Title from "@src/components/Title"
import styled from "@emotion/styled"
import Helmet from "react-helmet"
import { LoadingContext } from "@src/contexts/LoadingContext"

const BookWrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
`

const BookHeader = styled(Header)`
  position: relative;
  width: 100%;

  color: ${props => props.color || props.theme.colors.text};

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    padding-right: 20px;
  }
`

const BookMain = styled(Container)`
  position: relative;
  flex: 1;
  width: 100%;
  min-height: 0;
  padding-bottom: 50px;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    height: auto;
    padding-bottom: 0;
  }
`

const BookPage = ({ data, location }) => {
  const { stopLoading } = useContext(LoadingContext)

  const pageRef = useMemo(() => location.pathname.split("/").pop(), [
    location.pathname,
  ])
  const startPage = useMemo(
    () => data.markdownRemark.frontmatter.pages.find(p => p.ref === pageRef),
    [data, pageRef]
  )

  useEffect(() => {
    const preloadImage = src =>
      new Promise(r => {
        const image = new Image()
        image.onload = r
        image.onerror = r
        image.src = src
      })

    const pages = data.markdownRemark.frontmatter.pages
    const cover = data.markdownRemark.frontmatter.cover

    Promise.all([
      preloadImage(cover.childImageSharp.preview.src),
      ...pages.map(p => preloadImage(p.src.childImageSharp.preview.src)),
    ]).then(() => stopLoading())
  }, [data, stopLoading])

  const [defaultScale, setDefaultScale] = useState(null)

  useEffect(() => {
    setDefaultScale(
      Math.min(
        (window.innerWidth / 2800) * 0.54,
        (window.innerHeight / 1920) * 0.54
      )
    )
  }, [setDefaultScale])

  return (
    <BookWrapper>
      <Helmet>
        {data.markdownRemark.frontmatter.pages.map((p, i) => (
          <link
            key={i}
            rel="preload"
            href={p.src.childImageSharp.preview.src}
            as="image"
          />
        ))}
      </Helmet>
      <BookHeader color={data.markdownRemark.frontmatter.color}>
        <Title>Carnet {data.markdownRemark.frontmatter.name}</Title>
        <BookHeaderSection>
          <p>
            FORMAT — <span>{data.markdownRemark.frontmatter.format}</span>
          </p>
          <p>
            MATÉRIAU — <span>{data.markdownRemark.frontmatter.material}</span>
          </p>
        </BookHeaderSection>
        <BookHeaderSection>
          <p>
            PÉRIODE DE RÉDACTION —{" "}
            <span>{data.markdownRemark.frontmatter.date}</span>
          </p>
          <p>
            LIEU DE CONSERVATION ET COTE —{" "}
            <span>{data.markdownRemark.frontmatter.place}</span>
          </p>
        </BookHeaderSection>
      </BookHeader>
      <BookMain copyright={true}>
        {defaultScale && (
          <Book
            book={data.markdownRemark.frontmatter}
            defaultScale={defaultScale}
            startRef={startPage && startPage.ref}
          />
        )}
      </BookMain>
    </BookWrapper>
  )
}

const BookHeaderSection = styled.section`
  padding-bottom: 8px;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    padding-bottom: 5px;
  }

  p {
    font-size: ${props => props.theme.fontSizes.large.heading};
    margin-bottom: 0;
    margin-top: 10px;
    margin-left: 40px;

    @media (max-width: ${props => props.theme.breakpoints.large}) {
      font-size: ${props => props.theme.fontSizes.medium.heading};
      margin-top: 4px;
      margin-left: 2.75vw;
    }
  }
`

export default BookPage

export const pageQuery = graphql`
  query ProjectById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        name
        format
        material
        date
        place
        ratio
        color
        cover {
          childImageSharp {
            page: fixed(width: 2769, quality: 90) {
              aspectRatio
              ...GatsbyImageSharpFixed_noBase64
            }
            preview: resize(width: 840, quality: 70) {
              src
            }
            thumb: resize(width: 160, quality: 50) {
              src
            }
          }
        }
        pages {
          ref
          folio {
            right
            left
          }
          rotate
          jump
          src {
            childImageSharp {
              page: fixed(width: 2769, quality: 100) {
                aspectRatio
                ...GatsbyImageSharpFixed_noBase64
              }
              preview: resize(width: 840, quality: 70) {
                src
              }
              thumb: resize(width: 160, quality: 50) {
                src
              }
            }
          }
          items {
            title
            position
            content
            x
            y
            w
            h
          }
        }
      }
    }
  }
`
