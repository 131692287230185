import React from "react"
import BookManager from "./BookManager"

const WithBook = Component => ({ book, zooms, startRef, ...rest }) => (
  <BookManager {...(book || {})} zooms={zooms} startRef={startRef}>
    <Component {...rest} ratio={book.ratio} color={book.color} />
  </BookManager>
)

export default WithBook
