import styled from "@emotion/styled"

export default styled.h1`
  font-size: ${props => props.theme.fontSizes.large.title};
  text-transform: uppercase;
  font-family: "Institut De France";
  letter-spacing: 2px;
  box-sizing: border-box;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: lighter;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    font-size: ${props => props.theme.fontSizes.medium.title};
  }
`
