import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

export const BookContext = createContext({
  name: "",
  pages: [],
  cover: {},
  index: 0,
  zoom: 1,
  count: 0,
  isCover: true,
  isJump: false,
  currentPage: [],

  setPage: () => {},
  nextPage: () => {},
  prevPage: () => {},

  nextZoom: () => {},
  prevZoom: () => {},
})

const BookManager = props => {
  const { name, pages, cover, zooms, startRef, children } = props

  // States
  const [zoom, setZoom] = useState(props.zoom)
  const [count] = useState(pages.length)
  const [index, setIndex] = useState(() => {
    const startPage = pages.find(p => p.ref === startRef)
    return startPage ? pages.indexOf(startPage) : 0
  })
  const [isCover, setIsCover] = useState(index === 0 || index === count - 1)
  const [isJump, setIsJump] = useState(false)
  const currentPage = useMemo(() => pages[index], [index, pages])

  // Actions
  const nextPage = useCallback(() => {
    const newIndex = index + 1 >= count ? 0 : index + 1
    setIsCover(newIndex === 1 || newIndex === count - 1)
    setIsJump(pages[index].jump)
    setIndex(newIndex)
  }, [index, setIndex, count, setIsCover, setIsJump])

  const prevPage = useCallback(() => {
    const newIndex = index - 1 < 0 ? count - 1 : index - 1
    setIsCover(newIndex === 0 || newIndex === count - 2)
    setIsJump(pages[newIndex].jump)
    setIndex(newIndex)
  }, [index, setIndex, count, setIsCover, setIsJump])

  const setPage = useCallback(
    i => {
      setIsJump(false)
      setIsCover(
        i === 0 || i === count - 1 || index === 0 || index === count - 1
      )
      setIndex(Math.min(Math.max(i, 0), count))
    },
    [index, setIndex, count, setIsCover, setIsJump]
  )

  const nextZoom = useCallback(
    () => setZoom(zooms[Math.min(zooms.indexOf(zoom) + 1, zooms.length - 1)]),
    [zoom, setZoom]
  )

  const prevZoom = useCallback(
    () => setZoom(zooms[Math.max(zooms.indexOf(zoom) - 1, 0)]),
    [zoom, setZoom]
  )

  useEffect(() => {
    const basePath = `carnet-${name}`

    navigate(`${basePath}/${currentPage.ref}`, {
      state: {
        override: basePath,
      },
      replace: true,
    })
  }, [currentPage])

  return (
    <BookContext.Provider
      value={{
        name,
        pages,
        index,
        cover,
        isCover,
        isJump,
        zoom,
        count,
        currentPage,

        setPage,
        nextPage,
        prevPage,

        nextZoom,
        prevZoom,
      }}
    >
      {children}
    </BookContext.Provider>
  )
}

BookManager.propTypes = {
  name: PropTypes.string.isRequired,
  cover: PropTypes.object.isRequired,
  pages: PropTypes.array,
  zoom: PropTypes.number,
  index: PropTypes.number,
  zooms: PropTypes.arrayOf(PropTypes.number),
}

BookManager.defaultProps = {
  pages: [],
  zoom: 1,
  index: 0,
  zooms: [1, 1.5, 2],
}

export default BookManager
